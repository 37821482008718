import React from "react";
import Sidebar from "../../common/Sidebar";
const Dashboard = () => {
    return (
        <>
         <Sidebar />
            Dashboard
        </>
    )
}
export default Dashboard;