import React, { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../common/Sidebar";
import { toast } from "react-toastify";
import axios from "axios";
import { collection, query, getCountFromServer } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from "react-router-dom";
import "./style.css";

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [creators, setCreators] = useState(0);
  const [categories, setCategories] = useState(0);
  const [references, setReferences] = useState(0);
  const [tattoos, setTattoos] = useState(0);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      setLoading(true);
      fetchTotalCategories();
      fetchTotalCreators();
      fetchTotalReferences();
      fetchTotalTattoos();
      setLoading(false);
    }
  }, []);

  const data = [
    {
      name: "Categories",
      number: categories,
      icon: "fa fa-list text-white",
      url: "/categories"
    },
    {
      name: "AI-References",
      number: references,
      icon: "fa fa-share text-white",
      url: "/references"
    },
    {
      name: "Creators",
      number: creators,
      icon: "fa fa-user text-white",
      url: "/creators"
    },
    {
      name: "Tattoos",
      number: tattoos,
      icon: "fa fa-superpowers text-white",
      url: "/tattoos"
    },
  ];

  const fetchTotalCategories = async () => {
    try {
      const colRef = collection(db, "TattooCategories");
      const snapshot = await getCountFromServer(query(colRef));
      setCategories(snapshot.data().count);
    } catch (error) {
      console.error('Error fetching total count: ', error);
    }
  };

  const fetchTotalCreators = async () => {
    try {
      const colRef = collection(db, "Creators");
      const snapshot = await getCountFromServer(query(colRef));
      setCreators(snapshot.data().count);
    } catch (error) {
      console.error('Error fetching total count: ', error);
    }
  };

  const fetchTotalReferences = async () => {
    try {
      const colRef = collection(db, "References");
      const snapshot = await getCountFromServer(query(colRef));
      setReferences(snapshot.data().count);
    } catch (error) {
      console.error('Error fetching total count: ', error);
    }
  };

  const fetchTotalTattoos = async () => {
    try {
      const colRef = collection(db, "Tattoos");
      const snapshot = await getCountFromServer(query(colRef));
      setTattoos(snapshot.data().count);
    } catch (error) {
      console.error('Error fetching total count: ', error);
    }
  };

  return (
    <>
      <Sidebar />
      <section>
        {
          loading ?
            <div className="spinner">
              <HashLoader color="#757575" size={75} loading={true} />
            </div>
            :
            <div className="container-fluid">
              <div className="row">
                {data && data.map((item, i) => {
                  return (
                    <div className="col-xl-3 col-md-6 dashboard_card" key={i}>
                      <div className="stats_list  mb-3">
                        <div className="stat_box white_box position-relative py-4 px-4 px-xl-2 h-100 shadow-sm">
                          <Link to={item?.url} className="d-flex gap-3 align-items-center">
                            <span className="d-inline-flex justify-content-center align-items-center">
                              <i className={item.icon}></i>
                            </span>
                            <div>
                              <h5 className="mb-1">{item.number}</h5>
                              <p className="mb-0">{item.name}</p>
                            </div>

                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
        }

      </section>
    </>
  );
};
export default Home;
