import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import moment from "moment";
import { getDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import { HashLoader } from "react-spinners";

export default function ViewReference() {
    const [reference, setReference] = useState({});
    const [style, setStyle] = useState({});
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchReference();
    }, []);

    const fetchReference = async () => {
        setLoading(true);
        const referenceRef = doc(db, 'References', id);
        const referenceDoc = await getDoc(referenceRef);
        const data = referenceDoc.data();
        const fileRef = ref(storage, `References/${data?.id}/${data?.id}.png`);
        data.imageUrl = await getDownloadURL(fileRef);

        if (data?.thumbId) {
            const thumbRef = ref(storage, `References/${data?.id}/${data?.thumbId}.jpeg`);
            data.thumbUrl = thumbRef ? await getDownloadURL(thumbRef) : "";
        }

        if (data?.styleId) {
            const styleRef = doc(db, 'Text2ImgStyles', data?.styleId);
            const styleDoc = await getDoc(styleRef);
            const styleData = styleDoc.data();
            setStyle(styleData);
        }
        setReference(data);
        setLoading(false);
    };

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">AI-Reference Detail</h3>
                </div>
                <div className="table-responsive user_detail_card">
                    {loading ? (
                        <div className="spinner">
                            <HashLoader color="#757575" size={75} loading={true} />
                        </div>
                    ) : (
                        <Table className="mb-0">
                            <tbody>
                                <tr>
                                    <th>Prompt</th>
                                    <td>{reference?.prompt}</td>
                                </tr>
                                <tr>
                                    <th>Ranking</th>
                                    <td>{reference?.ranking}</td>
                                </tr>
                                <tr>
                                    <th>Style</th>
                                    <td>{style?.name}</td>
                                </tr>
                                <tr>
                                    <th>Image</th>
                                    <td><img src={`${reference?.imageUrl}`} /></td>
                                </tr>
                                {
                                    reference?.thumbUrl && <tr>
                                        <th>Thumbnail</th>
                                        <td><img src={`${reference?.thumbUrl}`} /></td>
                                    </tr>
                                }

                            </tbody>
                        </Table>)
                    }
                </div>
            </div>
        </div>
    );
}
