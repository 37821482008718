import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { CreatorSchema } from "../../validations";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import moment from "moment";
import { db } from '../../firebase';
import { collection, addDoc, doc, setDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';

export default function AddCreator() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

    }, []);
    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            instagram: "",
            tiktok: "",
            website: "",
        },
        validationSchema: CreatorSchema,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            try {

                const colRef = collection(db, "Creators");
                let q = query(colRef, where('name', '==', values.name));
                const snapshot = await getDocs(q);

                if (snapshot.docs.length > 0) {
                    toast.error('Creator name already exist')
                } else {

                    let links = {}
                    if (values.instagram !== "") {
                        links.instagram = values.instagram;
                    }
                    if (values.tiktok !== "") {
                        links.tiktok = values.tiktok;
                    }
                    if (values.website !== "") {
                        links.website = values.website;
                    }

                    const docRef = await addDoc(collection(db, 'Creators'), {
                        name: values.name,
                        description: values.description,
                        links: links
                    });
                    const autoId = docRef.id;
                    const resp = await updateDoc(doc(db, 'Creators', autoId), { id: autoId });

                    if (docRef?.id) {
                        toast.success('Creator details saved successfully');
                        navigate('/creators');
                        resetForm();
                    } else {
                        toast.error('Some problem exist, Please try again')
                    }
                }

            } catch (error) {
                toast.error('Some problem exist, Please try again')
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Add Creator</h3>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-3">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    placeholder="Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    name="name"
                                />
                                {formik.errors.name && formik.touched.name && (
                                    <div style={{ color: "red" }}>{formik.errors.name}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Instagram Link:</Form.Label>
                                <Form.Control
                                    placeholder="Instagram Link"
                                    value={formik.values.instagram}
                                    onChange={formik.handleChange}
                                    name="instagram"
                                />
                                {formik.errors.instagram && formik.touched.instagram && (
                                    <div style={{ color: "red" }}>{formik.errors.instagram}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Tiktok Link:</Form.Label>
                                <Form.Control
                                    placeholder="Tiktok Link"
                                    value={formik.values.tiktok}
                                    onChange={formik.handleChange}
                                    name="tiktok"
                                />
                                {formik.errors.tiktok && formik.touched.tiktok && (
                                    <div style={{ color: "red" }}>{formik.errors.tiktok}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Website Link:</Form.Label>
                                <Form.Control
                                    placeholder="Website Link"
                                    value={formik.values.website}
                                    onChange={formik.handleChange}
                                    name="website"
                                />
                                {formik.errors.website && formik.touched.website && (
                                    <div style={{ color: "red" }}>{formik.errors.website}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Description:</Form.Label>
                                <Form.Control
                                    placeholder="Description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    name="description"
                                    as="textarea"
                                />
                                {formik.errors.description && formik.touched.description && (
                                    <div style={{ color: "red" }}>{formik.errors.description}</div>
                                )}
                            </Form.Group>
                        </div>

                        <div className="col-md-12 mt-4">
                            {loading ?
                                <div className="spinner">
                                    <HashLoader color="#757575" size={75} loading={true} />
                                </div>
                                :
                                <Button className="px-4 add_btn" type="submit">
                                    Submit
                                </Button>
                            }

                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
