import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ref, off, query, limitToLast, get } from "firebase/database";


// ** Fetch Users
export const fetchCategories = createAsyncThunk(
    "fetchCategories",
    async (params) => {
        // try {
        //     let query = db.collection('TattooCategories').orderBy('id').limit(params.limit);
        //     if (params.page) {
        //         query = query.startAfter(params.page);
        //     }
        //     const snapshot = await query.get();
        //     const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        //     return data;
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        //     return [];
        // }

        // const response = await axios.get(
        //     `${APIURL}/getAllUsers`,
        //     config
        // );

        ///return response.data;
    }
);


export const categorySlice = createSlice({
    name: "category",
    initialState: {
        categories: [],
        total: 0,
        limit: 10,
        page: 1,
        isLoading: true,
        categoryDetail: {}
    },
    reducers: {
        changeLimit: (state, action) => {
            state.limit = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            console.log(action)
            // state.isLoading = true;
            // if (action?.payload?.code === 200) {
            //     state.users = action.payload?.body.users;
            //     state.total = action.payload?.body.total;
            //     state.isLoading = false;
            // }
        });

    },
});

export const { changeLimit, changePage } = categorySlice.actions;
export default categorySlice.reducer;
