import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { TattoSchema } from "../../validations";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import moment from "moment";
import { db, storage } from '../../firebase';
import { collection, addDoc, doc, setDoc, updateDoc, query, limit, getDocs } from 'firebase/firestore';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL, uploadBytes } from 'firebase/storage';

export default function AddTattoo() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [creators, setCreators] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetchData();
        fetchCreators();
        setLoading(false);
    }, []);

    const formik = useFormik({
        initialValues: {
            categoryIds: [],
            creatorId: "",
            images: "",
        },
        validationSchema: TattoSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                for (const image of values.images) {

                    const newUUID = uuidv4().toUpperCase();
                    const metadata = {
                        contentType: 'application/octet-stream'
                    };

                    const fileRef = ref(storage, `Tattoos/${newUUID}/${newUUID}`);

                    const uploadTask = await uploadBytes(fileRef, image, metadata);
                    const fileURL = await getDownloadURL(uploadTask.ref);
                    const randomString = generateRandomString();
                    createThumbnail(image, newUUID, randomString);

                    const docRef = doc(db, 'Tattoos', newUUID);
                    const recordData = {
                        id: newUUID,
                        categoryIds: values.categoryIds,
                        creatorId: values.creatorId,
                        likes: 0,
                        thumbId: randomString,
                        createdAt: moment().unix(),
                    };
                    await setDoc(docRef, recordData);
                }
            } catch (error) {
                toast.error('Some problem exist, Please try again')
            } finally {
                setLoading(false);
                if (values.images.length > 0) {
                    toast.success('Tattoo details saved successfully');
                    navigate('/tattoos');
                    resetForm();
                } else {
                    toast.error('Please select atleast one image')
                }
            }
        },
    });

    const createThumbnail = (file, directory, randomString) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set thumbnail dimensions
                const maxDim = 200;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxDim) {
                        height *= maxDim / width;
                        width = maxDim;
                    }
                } else {
                    if (height > maxDim) {
                        width *= maxDim / height;
                        height = maxDim;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(async (blob) => {
                    const thumbnailRef = ref(storage, `Tattoos/${directory}/${randomString}.jpeg`);
                    const thumbnailUploadTask = uploadBytesResumable(thumbnailRef, blob);

                    thumbnailUploadTask.on('state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            setUploadProgress(progress);
                            //console.log(`Thumbnail upload is ${progress}% done`);
                        },
                        (error) => {
                            console.error('Thumbnail upload failed', error);
                        },
                        async () => {
                            const thumbnailURL = await getDownloadURL(thumbnailUploadTask.snapshot.ref);
                            //alert('File and thumbnail uploaded successfully');
                        }
                    );
                }, 'image/jpeg', 0.75);
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    };

    const generateRandomString = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < 20; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    const fetchData = async () => {
        setLoading(true);
        try {
            const colRef = collection(db, "TattooCategories");
            let q = query(colRef, limit(1000));

            const snapshot = await getDocs(q);
            const docs = snapshot.docs.map(doc => ({ value: doc.id, label: doc.data()?.name }));
            setOptions(docs);
        } catch (err) {
            toast.error(err)
        } finally {
            setLoading(false);
        }
    };

    const fetchCreators = async () => {
        setLoading(true);
        try {
            const colRef = collection(db, "Creators");
            let q = query(colRef, limit(10000));

            const snapshot = await getDocs(q);
            const docs = snapshot.docs.map(doc => ({ value: doc.id, label: doc.data()?.name }));
            setCreators(docs);
        } catch (err) {
            toast.error(err)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Add Tattoo</h3>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-3">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3" controlId="">
                                <Form.Label>Categories</Form.Label>
                                <Select
                                    isMulti
                                    onChange={(option) =>
                                        formik.setFieldValue(
                                            "categoryIds",
                                            option !== null
                                                ? option.map((item) => item.value)
                                                : []
                                        )
                                    }
                                    name="categoryIds"
                                    options={options}
                                    placeholder="Select "
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                                <span style={{ color: "red" }}>
                                    {formik.touched.categoryIds && formik.errors.categoryIds ? (
                                        <div>{formik.errors.categoryIds}</div>
                                    ) : null}
                                </span>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Creator:</Form.Label>
                                <Select
                                    onChange={(item) =>
                                        formik.setFieldValue("creatorId", item.value)
                                    }
                                    name="creatorId"
                                    options={creators}
                                    placeholder="Select "
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                                {formik.errors.creatorId && formik.touched.creatorId && (
                                    <div style={{ color: "red" }}>{formik.errors.creatorId}</div>
                                )}
                            </Form.Group>
                        </div>

                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Images:</Form.Label>
                                <Form.Control
                                    name="images"
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={(e) => formik.setFieldValue('images', e.target.files)}
                                />
                                {formik.errors.images && formik.touched.images && (
                                    <div style={{ color: "red" }}>{formik.errors.images}</div>
                                )}
                            </Form.Group>
                        </div>

                        <div className="col-md-12 mt-4">
                            {loading ?
                                <div className="spinner">
                                    <HashLoader color="#757575" size={75} loading={true} />
                                </div>
                                :
                                <Button className="px-4 add_btn" type="submit">
                                    Submit
                                </Button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
