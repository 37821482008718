import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, changeLimit, changePage } from "../../apps/category";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { collection, getDocs, query, where, orderBy, limit, startAfter, getCountFromServer, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const Categories = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState(null);
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    const pageCount = useMemo(() => {
        const pageCounter = Math.ceil(total / itemsPerPage);
        return pageCounter;
    }, [total, itemsPerPage]);

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        fetchData();
        fetchTotalCount();
    }, [searchTerm, currentPage]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const colRef = collection(db, "TattooCategories");
            let q = query(colRef, orderBy('id'), limit(itemsPerPage));

            if (searchTerm) {
                q = query(colRef, where('name', '==', searchTerm));
            }

            if (currentPage > 0) {
                const startDoc = await getStartAfterDoc(currentPage);
                q = query(q, startAfter(startDoc));
            }

            const snapshot = await getDocs(q);
            const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            setData(docs);
            setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchOption = async (keyword) => {
        setSearchTerm(keyword);
    };

    const navigateToAddCategory = () => {
        navigate("/categories/create");
    };

    const fetchTotalCount = async () => {
        try {
            const colRef = collection(db, "TattooCategories");
            const snapshot = await getCountFromServer(query(colRef));
            setTotal(snapshot.data().count);
        } catch (error) {
            console.error('Error fetching total count: ', error);
        }
    };

    const getStartAfterDoc = async (page) => {
        let colRef = collection(db, "TattooCategories");
        let q = query(colRef, orderBy('id'), limit(itemsPerPage * (page)));
        const snapshot = await getDocs(q);
        return snapshot.docs[snapshot.docs.length - 1];
    };

    const limtHandler = (e) => {
        setItemsPerPage(e.target.value);
        setCurrentPage(0);
        setLastDoc(null);
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    const deleteItem = async (id) => {
        await swal({
            title: "Are you sure?",
            text: "Once deleted, you can't recover this",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const docRef = doc(db, "TattooCategories", id);
                const resp = await deleteDoc(docRef);
                setCurrentPage(0);
                setLastDoc(null);
                fetchData();
                fetchTotalCount();
                swal({
                    title: "Done!",
                    text: "deleted",
                    icon: "success",
                    timer: 2000,
                    button: false,
                });
            }
        });
    };

    return (
        <>
            <Sidebar />
            {loading ? (
                <div className="spinner">
                    <HashLoader color="#757575" size={75} loading={true} />
                </div>
            ) : (

                <div className="card">
                    <div className="card-header bg-white card-header-wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="mb-0">Categories</h3>

                            <div className="d-flex align-items-center justify-content-end gap-4">

                                {/* <div className="searchBox">
                                <input className="form-control" placeholder="Search" onChange={(e) => handleSearchOption(e.target.value)} value={searchTerm} />
                            </div> */}
                                <Button
                                    className="btn add_btn"
                                    onClick={navigateToAddCategory}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="table-responsive">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ?
                                        data.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{item?.id}</td>
                                                    <td>{item?.name}</td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                        <button
                                                            className="btn action_btn"
                                                            onClick={() => navigate(`/categories/${item?.id}/edit`)}
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </button>
                                                        <button
                                                            className="btn action_btn"
                                                            onClick={() => deleteItem(item?.id)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : <tr><td colSpan="3" className="text-center">No record found</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                        {data.length > 0 &&
                            <div className="d-flex justify-content-between align-items-center px-3 mb-2">
                                <div>
                                    <select className="form-select" onChange={limtHandler} value={itemsPerPage}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <div>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        pageCount={pageCount === 1 ? 1 : pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"pagination_prev"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"pagination_next"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        forcePage={currentPage}
                                    />
                                </div>
                            </div>
                        }
                    </>

                </div>
            )}
        </>
    );
};
export default Categories;
