import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Button, Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, changeLimit, changePage } from "../../apps/category";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { collection, getDocs, query, where, orderBy, limit, startAfter, getCountFromServer, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import moment from "moment";
import { ref, deleteObject, getDownloadURL } from 'firebase/storage';

const Tattoos = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState(null);
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0);
    const [checkedItems, setCheckedItems] = useState([]);
    const navigate = useNavigate();

    const pageCount = useMemo(() => {
        const pageCounter = Math.ceil(total / itemsPerPage);
        return pageCounter;
    }, [total, itemsPerPage]);

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        fetchData();
        fetchTotalCount();
    }, [searchTerm, currentPage]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const colRef = collection(db, "Tattoos");
            let q = query(colRef, orderBy('createdAt', 'desc'), limit(itemsPerPage));

            if (searchTerm) {
                q = query(colRef, where('name', '==', searchTerm));
            }

            if (currentPage > 0) {
                const startDoc = await getStartAfterDoc(currentPage);
                q = query(q, startAfter(startDoc));
            }

            const snapshot = await getDocs(q);
            const tattoos = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const tattoosWithRealtionData = await Promise.all(
                tattoos.map(async (tattoo) => {
                    let creator, categories;
                    if (tattoo?.creatorId) {
                        const userRef = doc(db, 'Creators', tattoo?.creatorId);
                        const userDoc = await getDoc(userRef);
                        creator = userDoc.data();
                    }

                    if (tattoo?.categoryIds.length > 0) {
                        const categoryRef = collection(db, "TattooCategories");
                        const q = query(categoryRef, where("id", "in", tattoo?.categoryIds));
                        const categoryDoc = await getDocs(q);
                        categories = categoryDoc.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    }
                    const fileRef = ref(storage, `Tattoos/${tattoo?.id}/${tattoo?.id}`);
                    let imageUrl = await getDownloadURL(fileRef);

                    return { ...tattoo, creator: creator, categories: categories, imageUrl: imageUrl };
                })
            );

            setData(tattoosWithRealtionData);
            setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchOption = async (keyword) => {
        setSearchTerm(keyword);
    };

    const fetchTotalCount = async () => {
        try {
            const colRef = collection(db, "Tattoos");
            const snapshot = await getCountFromServer(query(colRef));
            setTotal(snapshot.data().count);
        } catch (error) {
            console.error('Error fetching total count: ', error);
        }
    };

    const getStartAfterDoc = async (page) => {
        let colRef = collection(db, "Tattoos");
        let q = query(colRef, orderBy('createdAt', 'desc'), limit(itemsPerPage * (page)));
        const snapshot = await getDocs(q);
        return snapshot.docs[snapshot.docs.length - 1];
    };

    const limtHandler = (e) => {
        setCurrentPage(0);
        setLastDoc(null);
        setItemsPerPage(e.target.value)
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    const deleteItem = async (id, thumbId) => {

        await swal({
            title: "Are you sure?",
            text: "Once deleted, you can't recover this",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            setLoading(true);
            if (willDelete) {
                const fileRef = ref(storage, `Tattoos/${id}/${id}`);
                await deleteObject(fileRef);

                if (thumbId) {
                    const thumbRef = ref(storage, `Tattoos/${id}/${thumbId}.jpeg`);
                    await deleteObject(thumbRef);
                }

                const docRef = doc(db, "Tattoos", id);
                const resp = await deleteDoc(docRef);
                setCurrentPage(0);
                setLastDoc(null);
                fetchData();
                fetchTotalCount();
                swal({
                    title: "Done!",
                    text: "deleted",
                    icon: "success",
                    timer: 2000,
                    button: false,
                });
            }
            setLoading(false);
        });

    };

    const creatorData = async (creatorId) => {
        const userRef = doc(db, 'Creators', creatorId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            return userDoc.data?.name();
        }
        return null;
    }

    const categoriesData = async (categoryIds) => {
        const categoryRef = collection(db, "TattooCategories");
        const q = query(categoryRef, where("id", "in", categoryIds));
        const categoryDoc = await getDocs(q);
        const categories = categoryDoc.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }

    const handleCheckAll = () => {
        if (checkedItems.length === data.length) {
            setCheckedItems([]);
        } else {
            setCheckedItems(data.map((item) => item.id));
        }
    };

    const handleSingleCheck = (id) => {
        if (checkedItems.includes(id)) {
            const newCheckedItems = checkedItems.filter((itemId) => itemId !== id);
            setCheckedItems(newCheckedItems);
        } else {
            setCheckedItems([...checkedItems, id]);
        }
    };

    const handleBulkDelete = async () => {

        if (checkedItems.length > 0) {

            await swal({
                title: "Are you sure?",
                text: "Once deleted, you can't recover this",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                setLoading(true);
                if (willDelete) {
                    for (const id of checkedItems) {
                        const tattooRef = doc(db, 'Tattoos', id);
                        const tattooDoc = await getDoc(tattooRef);
                        const data = tattooDoc.data();

                        if (data?.thumbId) {
                            const fileRef = ref(storage, `Tattoos/${id}/${id}`);
                            const thumbRef = ref(storage, `Tattoos/${id}/${data?.thumbId}.jpeg`);
                            await deleteObject(fileRef);
                            await deleteObject(thumbRef);
                            await deleteDoc(tattooRef);
                        }
                    }
                    setCurrentPage(0);
                    setLastDoc(null);
                    fetchData();
                    fetchTotalCount();
                    swal({
                        title: "Done!",
                        text: "deleted",
                        icon: "success",
                        timer: 2000,
                        button: false,
                    });
                }
                setLoading(false);
            });

        } else {
            toast.error("No item selected to remove.")
        }
    }

    return (
        <>
            <Sidebar />
            {loading ? (
                <div className="spinner">
                    <HashLoader color="#757575" size={75} loading={true} />
                </div>
            ) : (

                <div className="card">
                    <div className="card-header bg-white card-header-wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="mb-0">Tattoos</h3>

                            <div className="d-flex align-items-center justify-content-end gap-4">

                                {/* <div className="searchBox">
                                <input className="form-control" placeholder="Search" onChange={(e) => handleSearchOption(e.target.value)} value={searchTerm} />
                            </div> */}
                                <Button
                                    className="btn add_btn"
                                    onClick={() => navigate("/tattoos/add")}
                                >
                                    Add
                                </Button>
                                <Button
                                    className="btn add_btn"
                                    onClick={handleBulkDelete}
                                >
                                    Delete All
                                </Button>
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="table-responsive">
                            <Table>
                                <thead>
                                    <tr>
                                        <th align="center" style={{ width: "50px" }}>
                                            <Form.Group controlId="formBasicCheckbox1">
                                                <Form.Check
                                                    type="checkbox"
                                                    className="d-inline-flex align-items-center"
                                                    checked={data.length === checkedItems.length}
                                                    onChange={handleCheckAll}
                                                />
                                            </Form.Group>
                                        </th>
                                        <th>Id</th>
                                        <th>Categories</th>
                                        <th>Creator</th>
                                        <th>Likes</th>
                                        <th>Tattoo</th>
                                        <th>Created At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ?
                                        data.map((item, i) => {

                                            return (
                                                <tr key={i}>
                                                    <td align="center" style={{ minWidth: "50px" }}>
                                                        <Form.Group controlId="trashPeople{item._id}">
                                                            <Form.Check type="checkbox"
                                                                className="d-inline-flex align-items-center"
                                                                checked={checkedItems.includes(item.id)}
                                                                onChange={() => handleSingleCheck(item.id)}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td>{item?.id}</td>
                                                    <td>{item?.categories?.length > 0 && item?.categories.map((ele) => {
                                                        return (<span className="tattoo-cat" key={ele?.id}>{ele?.name}</span>)
                                                    })}</td>
                                                    <td>{item?.creator?.name}</td>
                                                    <td>{item?.likes}</td>
                                                    <td><img height="50" src={`${item?.imageUrl}`} /></td>
                                                    <td>{moment.unix(item?.createdAt).format('YYYY-MM-DD HH:mm A')}</td>
                                                    <td style={{ whiteSpace: "nowrap" }}>
                                                        <button
                                                            className="btn action_btn"
                                                            onClick={() => navigate(`/tattoos/${item?.id}/edit`)}
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </button>
                                                        <button
                                                            className="btn action_btn"
                                                            onClick={() => navigate(`/tattoos/${item?.id}`)}
                                                        >
                                                            <i className="fa fa-eye"></i>
                                                        </button>
                                                        <button
                                                            className="btn action_btn"
                                                            onClick={() => deleteItem(item?.id, item?.thumbId)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : <tr><td colSpan="6" className="text-center">No record found</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                        {data.length > 0 &&
                            <div className="d-flex justify-content-between align-items-center px-3 mb-2">
                                <div>
                                    <select className="form-select" onChange={limtHandler} value={itemsPerPage}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <div>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        pageCount={pageCount === 1 ? 1 : pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"pagination_prev"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"pagination_next"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        forcePage={currentPage}
                                    />

                                </div>
                            </div>
                        }
                    </>

                </div >
            )}
        </>
    );
};
export default Tattoos;
