import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function PageNotFound() {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.user);

  return (
    <div>
      <section className="page_404">
        <div className="container">
          <div className="row">
            <h1>Oops!</h1>
            <h2>Page Not Found</h2>
            <h6>
              We could not find what you were looking for. Please contact the
              owner of the site that linked you to the original URL and let them
              know their link is broken.
            </h6>
            <div className="col-6">

              <button className="link_404" onClick={() => (!currentUser || currentUser?.uid !== 'oJZjTIVedih0Y5M05zLl9OWsvoG3') ? navigate("/login") : navigate("/dashboard")}>Go To Home</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
