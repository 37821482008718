import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { TattoSchema } from "../../validations";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import moment from "moment";
import { db, storage } from '../../firebase';
import { collection, addDoc, doc, setDoc, updateDoc, query, limit, getDocs, where, getDoc } from 'firebase/firestore';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL, uploadBytes } from 'firebase/storage';
import Papa from 'papaparse';
import axios from "axios";

export default function Import() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [creators, setCreators] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [csvData, setCsvData] = useState([]);

    const formik = useFormik({
        initialValues: {
            image: "",
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                if (csvData.length > 0) {
                    for (const row of csvData) {
                        let categoryIds = [];
                        let creatorId = "";
                        if (row['Categories'].length > 0) {
                            const categories = row['Categories'].split(',');
                            const categoryRef = collection(db, "TattooCategories");
                            const q = query(categoryRef, where("name", "in", categories));
                            const categoryDoc = await getDocs(q);
                            categoryIds = categoryDoc.docs.map(doc => {
                                return doc.id;
                            });
                        }
                        if (row['Creator'].length > 0) {
                            const usersRef = collection(db, 'Creators');
                            const qy = query(usersRef, where('name', '==', row['Creator']));
                            const querySnapshot = await getDocs(qy);

                            if (!querySnapshot.empty) {
                                creatorId = querySnapshot.docs[0].id;
                            }
                        }
                        if (isImageUrl(row['Image'])) {
                            const response = await fetch(row['Image']);
                            if (response.ok) {
                                const blob = await response.blob();
                                const newUUID = uuidv4().toUpperCase();
                                const metadata = {
                                    contentType: 'application/octet-stream'
                                };
                                const fileRef = ref(storage, `Tattoos/${newUUID}/${newUUID}`);
                                //const uploadTask = uploadBytesResumable(fileRef, blob, metadata);

                                const uploadTask = await uploadBytes(fileRef, blob, metadata);
                                const fileURL = await getDownloadURL(uploadTask.ref);
                                const randomString = generateRandomString();
                                createThumbnail(blob, newUUID, randomString);

                                const docRef = doc(db, 'Tattoos', newUUID);

                                const recordData = {
                                    id: newUUID,
                                    categoryIds: categoryIds,
                                    creatorId: creatorId,
                                    likes: 0,
                                    thumbId: randomString,
                                    createdAt: moment().unix(),
                                };
                                await setDoc(docRef, recordData);
                            } else {
                                toast.error(`Tattoo link ${row['Image']} is not imported`)
                            }
                        }
                    }

                } else {
                    toast.error('Please select csv file')
                }

            } catch (error) {
                console.log(error)
                toast.error('Some problem exist, Please try again')
            } finally {
                setLoading(false);
                navigate('/tattoos');
                resetForm();
                toast.success('CSV Import successfully')
            }
        },
    });

    const createThumbnail = (file, directory, randomString) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set thumbnail dimensions
                const maxDim = 200;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxDim) {
                        height *= maxDim / width;
                        width = maxDim;
                    }
                } else {
                    if (height > maxDim) {
                        width *= maxDim / height;
                        height = maxDim;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(async (blob) => {
                    const thumbnailRef = ref(storage, `Tattoos/${directory}/${randomString}.jpeg`);
                    const thumbnailUploadTask = uploadBytesResumable(thumbnailRef, blob);

                    thumbnailUploadTask.on('state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            setUploadProgress(progress);
                            //console.log(`Thumbnail upload is ${progress}% done`);
                        },
                        (error) => {
                            console.error('Thumbnail upload failed', error);
                        },
                        async () => {
                            const thumbnailURL = await getDownloadURL(thumbnailUploadTask.snapshot.ref);
                            //alert('File and thumbnail uploaded successfully');
                        }
                    );
                }, 'image/jpeg', 0.75);
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                setCsvData(result.data);
            },
        });
    };

    const isImageUrl = (url) => {
        return /\.(jpg|jpeg|png|gif|bmp)$/.test(url);
    };

    const generateRandomString = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < 20; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Import Tattoo</h3>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-3">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>CSV File:</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept=".csv"
                                    onChange={handleFileUpload}
                                />
                                {/* {formik.errors.image && formik.touched.image && (
                                    <div style={{ color: "red" }}>{formik.errors.image}</div>
                                )} */}
                            </Form.Group>
                        </div>

                        <div className="col-md-12 mt-4">
                            {loading ?
                                <div className="spinner">
                                    <HashLoader color="#757575" size={75} loading={true} />
                                </div>
                                :
                                <Button className="px-4 add_btn" type="submit">
                                    Submit
                                </Button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
