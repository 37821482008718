import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const UserAddSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    // .matche(phoneRegExp,'Phone number is not valid')

    .required("Required"),
  country: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string()
    .min(4, "Too Short!")
    .max(10, "Too Long!")
    .required("Required"),

  email: Yup.string().email("Invalid email").required("Required"),
});
export const UserEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    // .matche(phoneRegExp,'Phone number is not valid')

    .required("Required"),
  country: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),


  email: Yup.string().email("Invalid email").required("Required"),
});

export const CategorySchema = Yup.object().shape({
  name: Yup.string().trim().required("Required"),
});

export const CreatorSchema = Yup.object().shape({
  name: Yup.string().trim().required("Required"),
  //description: Yup.string().required("Required"),
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

export const ReferenceSchema = Yup.object().shape({
  prompt: Yup.string().trim().required("Required"),
  //ranking: Yup.string().required("Required"),
  image: Yup.string().required("Required"),
  styleId: Yup.string().required("Required"),
});

export const EditReferenceSchema = Yup.object().shape({
  prompt: Yup.string().trim().required("Required"),
  //ranking: Yup.string().required("Required"),
  styleId: Yup.string().required("Required"),
});

export const TattoSchema = Yup.object().shape({
  categoryIds: Yup.array().required('Required').min(1, "Required"),
  images: Yup.string().required("Required"),
});

export const EditTattoSchema = Yup.object().shape({
  categoryIds: Yup.array().required('Required').min(1, "Required"),
});

export const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export const resetPasswordSchema = Yup.object({
  currentPassword: Yup.string().required("Current password is required."),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/,
      "Password must meet the criteria of 8-16 alphanumeric character with min 1 special character."
    )
    .required("Password is required."),
  passwordConfirm: Yup.string()
    .required("Confirm password is required.")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
});