import React from "react";
import { Dropdown } from 'react-bootstrap';
import './common.css'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    const addClass = () => {
        document.getElementById("togglesidebar").classList.add('active_sidebar')
    }
    return (
        <>
            <header className="d-flex align-items-center">
                <button className="btn sidebar_btn d-inline-flex align-items-center justify-content-center d-xxl-none d-xl-none" onClick={addClass}>
                    <img src={require('../assets/images/dashboard.svg').default} alt="dashboard" />
                </button>
                <div className="text-end">
                    <Dropdown className="position-relative">
                        <Dropdown.Toggle variant="unset" id="dropdown-basic" className="border-0 d-flex gap-2 align-items-center">
                            <i className="fa fa-user rounded-circle d-flex justify-content-center align-items-center user-icon"></i><span>
                                {user?.firstName} {user?.lastName ?? ''}
                            </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => navigate('/changepassword')}><i className="fa fa-pencil me-3"></i><span>Change Password</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </header>
        </>
    )
}
export default Header;