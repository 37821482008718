import React from "react";
import "./layout.css";
import { Routes, Route } from "react-router-dom";
import Home from "../Admin/Home/Home";
import Login from "../Admin/Login/Login";

import ProtectedRoutes from "../Admin/ProtectedRoutes/ProtectedRoutes";
import Dashboard from "../Admin/Dashboard/Dashboard";
import PageNotFound from "../Admin/PageNotFound/PageNotFound";

import Categories from "../Admin/Category/Categories";
import AddCategory from "../Admin/Category/AddCategory";
import EditCategory from "../Admin/Category/EditCategory";

import Creators from "../Admin/Creator/List";
import AddCreator from "../Admin/Creator/Add";
import EditCreator from "../Admin/Creator/Edit";

import References from "../Admin/AI-Reference/List";
import AddReference from "../Admin/AI-Reference/Add";
import ViewReference from "../Admin/AI-Reference/View";
import EditReference from "../Admin/AI-Reference/Edit";

import Tattoos from "../Admin/Tattoo/List";
import AddTattoo from "../Admin/Tattoo/Add";
import ViewTattoo from "../Admin/Tattoo/View";
import EditTattoo from "../Admin/Tattoo/Edit";
import ImportTattoo from "../Admin/Tattoo/Import";

import ChangePassword from "../Admin/Login/ChangePassword";

const Routing = () => {

  return (
    <>
      {/* auth routes */}
      <Routes>
        <Route exact={true} path="/login" element={<Login />} />
        <Route exact={true} path="*" element={<PageNotFound />} />

        {/* protected routes */}

        <Route
          exact={true}
          path="/"
          element={<ProtectedRoutes Component={Home} />}
        />

        <Route
          exact={true}
          path="/dashboard"
          element={<ProtectedRoutes Component={Home} />}
        />

        <Route
          exact
          path="/categories"
          element={<ProtectedRoutes Component={Categories} />}
        />

        <Route
          exact
          path="/categories/create"
          element={<ProtectedRoutes Component={AddCategory} />}
        />

        <Route
          exact
          path="/categories/:id/edit"
          element={<ProtectedRoutes Component={EditCategory} />}
        />

        <Route
          exact
          path="/creators"
          element={<ProtectedRoutes Component={Creators} />}
        />

        <Route
          exact
          path="/creators/add"
          element={<ProtectedRoutes Component={AddCreator} />}
        />

        <Route
          exact
          path="/creators/:id/edit"
          element={<ProtectedRoutes Component={EditCreator} />}
        />

        <Route
          exact
          path="/references"
          element={<ProtectedRoutes Component={References} />}
        />
        <Route
          exact
          path="/references/add"
          element={<ProtectedRoutes Component={AddReference} />}
        />
        <Route
          exact
          path="/references/:id"
          element={<ProtectedRoutes Component={ViewReference} />}
        />
        <Route
          exact
          path="/references/:id/edit"
          element={<ProtectedRoutes Component={EditReference} />}
        />

        <Route
          exact
          path="/tattoos"
          element={<ProtectedRoutes Component={Tattoos} />}
        />
        <Route
          exact
          path="/tattoos/add"
          element={<ProtectedRoutes Component={AddTattoo} />}
        />
        <Route
          exact
          path="/tattoos/:id"
          element={<ProtectedRoutes Component={ViewTattoo} />}
        />
        <Route
          exact
          path="/tattoos/:id/edit"
          element={<ProtectedRoutes Component={EditTattoo} />}
        />

        <Route
          exact
          path="/tattoos/import"
          element={<ProtectedRoutes Component={ImportTattoo} />}
        />

        <Route
          exact
          path="/changepassword"
          element={<ProtectedRoutes Component={ChangePassword} />}
        />

      </Routes>
    </>
  );
};
export default Routing;
