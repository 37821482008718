// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    background-color: #f8f8f8;
    min-height: 100vh;
}

.main {
    padding: 10px 10px 10px 300px;
}

@media (max-width:1199px) {
    .main {
        padding: 10px 10px 10px 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Layout/layout.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".wrapper {\n    background-color: #f8f8f8;\n    min-height: 100vh;\n}\n\n.main {\n    padding: 10px 10px 10px 300px;\n}\n\n@media (max-width:1199px) {\n    .main {\n        padding: 10px 10px 10px 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
