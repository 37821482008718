import React, { useEffect } from "react";
import "./common.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const removeClass = () => {
    document.getElementById("togglesidebar").classList.remove("active_sidebar");
  };

  return (
    <>

      <div id="sidebar">
        <div className="sidebar_logo mx-auto">

          <img src="#" alt="logo" />
          <i className="fa fa-close" onClick={removeClass}></i>

        </div>
        <div className="sidebar_menu">
          <ul className="m-0 p-0">
            <li>
              <Link
                to="/dashboard"
                className={location.pathname === "/dashboard" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/home.svg").default}
                  alt="home"
                />
                Home
              </Link>
            </li>

            <li>
              <Link
                to="/categories"
                className={location.pathname === "/categories" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/categories.svg").default}
                  alt="categories"
                />
                Categories
              </Link>
            </li>
            <li>
              <Link
                to="/references"
                className={location.pathname === "/references" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/share.svg").default}
                  alt="references"
                />
                AI-References
              </Link>
            </li>
            <li>
              <Link
                to="/creators"
                className={location.pathname === "/creators" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/users.svg").default}
                  alt="creatorsn"
                />
                Creators
              </Link>
            </li>
            <li>
              <Link
                to="/tattoos"
                className={location.pathname === "/tattoos" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/tattoo.svg").default}
                  alt="tattoos"
                />
                Tattoos
              </Link>
            </li>
            <li>
              <Link
                to="/login"
                className={location.pathname === "" ? "active" : ""}
                onClick={() => {
                  localStorage.removeItem("token");
                  localStorage.removeItem("userData");
                  navigate('/login')
                }}
              >
                <img
                  src={require("../assets/images/logout.png")}
                  alt="help"
                />
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="sidebar_overlay" onClick={removeClass}></div>
    </>
  );
};
export default Sidebar;
