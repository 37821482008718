import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { db } from "../firebase";

export const login = createAsyncThunk(
  "user/login",
  async (params) => {
    const response = await signInWithEmailAndPassword(auth, params.email, params.password);
    return response;
  }
);


export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (params) => {
    // const config = {
    //   headers: {
    //     Authorization: localStorage.getItem("token"),
    //   }
    // };
    // const response = await axios.post(
    //   `${APIURL}/changePassword`,
    //   params,
    //   config
    // );

    // return response;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
    token: localStorage.getItem('token') ? localStorage.getItem('token') : "",
    userLoggedIn: false,
    Activeusers: 0,
    Inactiveusers: 0,
    Activeposts: 0,
    Inactiveposts: 0,
    Reportedposts: 0,
    todayTotalUsers: 0,
    todayNewusers: 0,
    last30DaysTotalUsers: 0,
    last30DaysNewUsers: 0
  },
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(login.fulfilled, (state, action) => {
      if (action?.payload?.user?.email === 'admin-tattoapp@yopmail.com') {
        state.user = action?.payload?.user;
        state.userLoggedIn = true;
        localStorage.setItem('userData', JSON.stringify(action?.payload?.user))
        localStorage.setItem('token', action?.payload?.user?.accessToken);
      }
    });
  },
});

export default authSlice.reducer;
