import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { CategorySchema } from "../../validations";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import moment from "moment";
import { db } from '../../firebase';
import { collection, addDoc, doc, setDoc, updateDoc, getDoc, query, where, getDocs } from 'firebase/firestore';

export default function EditCategory() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        fetchCateory();
    }, []);

    const formik = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: CategorySchema,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            try {
                const colRef = collection(db, "TattooCategories");
                let q = query(colRef, where('name', '==', values.name));
                const snapshot = await getDocs(q);
                const existingRecord = snapshot.docs.find(doc => doc.id !== id);

                if (existingRecord) {
                    toast.error('Category name already exist')
                } else {

                    const docRef = doc(db, 'TattooCategories', id);
                    await updateDoc(docRef, values);

                    toast.success('Category updated successfully');
                    navigate('/categories');
                }

            } catch (error) {
                console.log(error)
                toast.error('Some problem exist, Please try again')
            } finally {
                setLoading(false);
            }
        },
    });

    const fetchCateory = async () => {
        setLoading(true);
        const categoryRef = doc(db, 'TattooCategories', id);
        const categoryDoc = await getDoc(categoryRef);
        const data = categoryDoc.data();
        formik.setFieldValue('name', data?.name);
        setLoading(false);
    };

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Edit Category</h3>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-3">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    placeholder="Category Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    name="name"
                                />
                                {formik.errors.name && formik.touched.name && (
                                    <div style={{ color: "red" }}>{formik.errors.name}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-12 mt-4">
                            {loading ?
                                <div className="spinner">
                                    <HashLoader color="#757575" size={75} loading={true} />
                                </div>
                                :
                                <Button className="px-4 add_btn" type="submit">
                                    Submit
                                </Button>
                            }

                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
