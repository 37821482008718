import React, { useState, useEffect } from 'react';
import { Button, Container, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { LockFill } from 'react-bootstrap-icons';

// import "./login.css";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LoginSchema } from "../../validations";
import LoadingButton from "../../common/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../apps/auth";

export default function Login() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const resp = await dispatch(login(values));

        if (resp?.payload?.user?.email === 'admin-tattoapp@yopmail.com') {
          navigate("/dashboard");
          toast.success('Login successfully');
        } else {
          toast.error('Wrong email or password')
        }
        setIsLoading(false);

      } catch (error) {
        setIsLoading(false)
        console.log(error)
        ///toast.error(error.response.data.message);
      }
    },
  });

  return (
    <Container className="d-flex align-items-center justify-content-center vh-100">
      <div className="w-100" style={{ maxWidth: '400px' }}>
        <div className="text-center">
          <LockFill size={64} color="black" />
          <h1 className="mt-3">Sign in</h1>
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup className="mb-3">
            <FormLabel>Email Address</FormLabel>
            <FormControl type="email" value={formik.values.email}
              onChange={formik.handleChange}
              name="email" required autoComplete="email" />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel>Password</FormLabel>
            <FormControl type="password" value={formik.values.password}
              onChange={formik.handleChange}
              name="password" required autoComplete="current-password" />
          </FormGroup>
          <FormGroup className="mb-3">
            <Form.Check type="checkbox" id="remember" label="Remember me" />
          </FormGroup>
          <div className="d-grid gap-2 mt-3">
            <Button type="submit" className="btn signIn-btn">
              {isLoading ? <LoadingButton /> : "Sign in"}
            </Button>
          </div>
        </Form>

      </div>
    </Container>
  );
}
