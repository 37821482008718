import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { EditReferenceSchema } from "../../validations";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import moment from "moment";
import { db, storage } from '../../firebase';
import Select from 'react-select';
import { collection, addDoc, doc, setDoc, updateDoc, query, limit, getDocs, getDoc, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export default function EditReference() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [styles, setStyles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { id } = useParams();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        fetchReference();
        fetchStyles();
    }, []);

    const formik = useFormik({
        initialValues: {
            prompt: "",
            ranking: "",
            image: "",
            styleId: "",
            thumbId: "",
        },
        validationSchema: EditReferenceSchema,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            try {

                const colRef = collection(db, "References");
                let q = query(colRef, where('prompt', '==', values.prompt));
                const snapshot = await getDocs(q);
                const existingRecord = snapshot.docs.find(doc => doc.id !== id);

                if (existingRecord) {
                    toast.error('Reference prompt already exist')
                } else {

                    if (values.image) {
                        const fileRef = ref(storage, `References/${id}/${id}.png`);
                        const uploadTask = uploadBytesResumable(fileRef, values.image);

                        uploadTask.on('state_changed',
                            (snapshot) => {
                                setLoading(true);
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                setUploadProgress(progress);
                            },
                            (error) => {
                                toast.error('Upload failed')
                            },
                            async () => {
                                const fileURL = await getDownloadURL(uploadTask.snapshot.ref);
                                createThumbnail(values.image, id, values.thumbId);

                                const resp = await updateDoc(doc(db, 'References', id), {
                                    prompt: values.prompt,
                                    ranking: values.ranking,
                                    styleId: values.styleId,
                                    thumbId: values.thumbId
                                });

                                toast.success('Reference details updated successfully');
                                navigate('/references');
                            }
                        );
                    } else {
                        setLoading(true);
                        const resp = await updateDoc(doc(db, 'References', id), {
                            prompt: values.prompt,
                            ranking: values.ranking,
                            styleId: values.styleId,
                            thumbId: values.thumbId
                        });

                        toast.success('Reference details updated successfully');
                        navigate('/references');
                    }
                }

            } catch (error) {
                console.log(error)
                toast.error('Some problem exist, Please try again')
            } finally {
                setLoading(false);
            }
        },
    });

    const fetchStyles = async () => {
        setLoading(true);
        try {
            const colRef = collection(db, "Text2ImgStyles");
            let q = query(colRef, limit(10000));

            const snapshot = await getDocs(q);
            const docs = snapshot.docs.map(doc => ({ value: doc.id, label: doc.data()?.name }));
            setStyles(docs);
        } catch (err) {
            toast.error(err)
        } finally {
            setLoading(false);
        }
    };

    const createThumbnail = (file, directory, randomString) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set thumbnail dimensions
                const maxDim = 200;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxDim) {
                        height *= maxDim / width;
                        width = maxDim;
                    }
                } else {
                    if (height > maxDim) {
                        width *= maxDim / height;
                        height = maxDim;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(async (blob) => {
                    const thumbnailRef = ref(storage, `References/${directory}/${randomString}.jpeg`);
                    const thumbnailUploadTask = uploadBytesResumable(thumbnailRef, blob);

                    thumbnailUploadTask.on('state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            setUploadProgress(progress);
                            //console.log(`Thumbnail upload is ${progress}% done`);
                        },
                        (error) => {
                            console.error('Thumbnail upload failed', error);
                        },
                        async () => {
                            const thumbnailURL = await getDownloadURL(thumbnailUploadTask.snapshot.ref);
                            //alert('File and thumbnail uploaded successfully');
                        }
                    );
                }, 'image/jpeg', 0.75);
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    };

    const fetchReference = async () => {
        setLoading(true);
        const referenceRef = doc(db, 'References', id);
        const referenceDoc = await getDoc(referenceRef);
        const data = referenceDoc.data();
        const fileRef = ref(storage, `References/${data?.id}/${data?.id}.png`);
        const imageUrl = await getDownloadURL(fileRef);

        const thumbRef = null; //ref(storage, `References/${data?.id}/${data?.thumbId}.jpeg`);
        const thumbUrl = thumbRef ? await getDownloadURL(thumbRef) : "";

        formik.setFieldValue('prompt', data?.prompt);
        formik.setFieldValue('ranking', data?.ranking);
        formik.setFieldValue('styleId', data?.styleId);
        formik.setFieldValue('thumbId', data?.thumbId);
        setUrl(imageUrl);
        setLoading(false);
    };

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Edit Reference</h3>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-3">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Prompt:</Form.Label>
                                <Form.Control
                                    placeholder="Prompt"
                                    value={formik.values.prompt}
                                    onChange={formik.handleChange}
                                    name="prompt"
                                />
                                {formik.errors.prompt && formik.touched.prompt && (
                                    <div style={{ color: "red" }}>{formik.errors.prompt}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Ranking:</Form.Label>
                                <Form.Control
                                    placeholder="Ranking"
                                    value={formik.values.ranking}
                                    onChange={formik.handleChange}
                                    name="ranking"
                                    type="number"
                                    min="0"
                                />
                                {formik.errors.ranking && formik.touched.ranking && (
                                    <div style={{ color: "red" }}>{formik.errors.ranking}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Style:</Form.Label>
                                <Select
                                    onChange={(item) =>
                                        formik.setFieldValue("styleId", item.value)
                                    }
                                    value={styles.filter(
                                        (option) =>
                                            formik.values.styleId == option.value
                                    )}
                                    name="styleId"
                                    options={styles}
                                    placeholder="Select "
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                                {formik.errors.styleId && formik.touched.styleId && (
                                    <div style={{ color: "red" }}>{formik.errors.styleId}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Image:</Form.Label>
                                <Form.Control
                                    name="image"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => formik.setFieldValue('image', e.target.files[0])}
                                />
                                {url && <a href={url} target="_blank">See Image</a>}
                                {formik.errors.image && formik.touched.image && (
                                    <div style={{ color: "red" }}>{formik.errors.image}</div>
                                )}
                            </Form.Group>
                        </div>

                        <div className="col-md-12 mt-4">
                            {loading ?
                                <>
                                    <div className="spinner">
                                        <HashLoader color="#757575" size={75} loading={true} />
                                    </div>
                                    {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}
                                </>
                                :
                                <Button className="px-4 add_btn" type="submit">
                                    Submit
                                </Button>
                            }

                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
