import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { resetPasswordSchema } from "../../validations";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";

export default function ChangePassword() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const currentUser = useSelector((state) => state.auth.user);

    const auth = getAuth();
    const user = auth.currentUser;

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            passwordConfirm: ""
        },
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                const credential = EmailAuthProvider.credential(user?.email, values.currentPassword);
                const resp = await reauthenticateWithCredential(user, credential);

                await updatePassword(user, values.newPassword);
                toast.success('Password updated successfully');
                navigate('/');
                resetForm();

            } catch (error) {
                console.log(error)
                toast.error('Invalid Password, Please try again')
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Change Password</h3>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-3">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Current Password:</Form.Label>
                                <Form.Control
                                    placeholder="Current Password"
                                    value={formik.values.currentPassword}
                                    onChange={formik.handleChange}
                                    name="currentPassword"
                                    type="password"
                                />
                                {formik.errors.currentPassword && formik.touched.currentPassword && (
                                    <div style={{ color: "red" }}>{formik.errors.currentPassword}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>New Password:</Form.Label>
                                <Form.Control
                                    placeholder="New Password"
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    name="newPassword"
                                    type="password"
                                />
                                {formik.errors.newPassword && formik.touched.newPassword && (
                                    <div style={{ color: "red" }}>{formik.errors.newPassword}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Confirm Password:</Form.Label>
                                <Form.Control
                                    placeholder="Confirm Password"
                                    value={formik.values.passwordConfirm}
                                    onChange={formik.handleChange}
                                    name="passwordConfirm"
                                    type="password"
                                />
                                {formik.errors.passwordConfirm && formik.touched.passwordConfirm && (
                                    <div style={{ color: "red" }}>{formik.errors.passwordConfirm}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-12 mt-4">
                            {loading ?
                                <div className="spinner">
                                    <HashLoader color="#757575" size={75} loading={true} />
                                </div>
                                :
                                <Button className="px-4 add_btn" type="submit">
                                    Submit
                                </Button>
                            }

                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
