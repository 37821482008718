import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";


export default function ProtectedRoutes({ Component }) {
  const currentUser = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!currentUser || currentUser?.uid !== 'oJZjTIVedih0Y5M05zLl9OWsvoG3') {
      navigate('/login')
    }
  }, [])

  return (
    <>
      <Component />
    </>
  )
}

