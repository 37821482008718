import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import moment from "moment";
import { getDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import { HashLoader } from "react-spinners";

export default function ViewTattoo() {
    const [tattoo, setTattoo] = useState({});
    const [creator, setCreator] = useState({});
    const [categories, setCategories] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTatto();
    }, []);

    const fetchTatto = async () => {
        setLoading(true);
        const tattooRef = doc(db, 'Tattoos', id);
        const tattooDoc = await getDoc(tattooRef);
        const data = tattooDoc.data();
        const fileRef = ref(storage, `Tattoos/${data?.id}/${data?.id}`);
        data.imageUrl = await getDownloadURL(fileRef);
        if (data?.thumbId) {
            const thumbRef = ref(storage, `Tattoos/${data?.id}/${data?.thumbId}.jpeg`);
            data.thumbUrl = thumbRef ? await getDownloadURL(thumbRef) : "";
        }

        setTattoo(data);

        if (data?.creatorId) {
            const userRef = doc(db, 'Creators', data?.creatorId);
            const userDoc = await getDoc(userRef);
            const creatorData = userDoc.data();
            setCreator(creatorData);
        }

        if (data?.categoryIds?.length > 0) {
            const categoryRef = collection(db, "TattooCategories");
            const q = query(categoryRef, where("id", "in", data?.categoryIds));
            const categoryDoc = await getDocs(q);
            const categoriesData = categoryDoc.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCategories(categoriesData);
        }
        setLoading(false);
    };

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Tattoo Detail</h3>
                </div>
                <div className="table-responsive user_detail_card">
                    {loading ? (
                        <div className="spinner">
                            <HashLoader color="#757575" size={75} loading={true} />
                        </div>
                    ) : (
                        <Table className="mb-0">
                            <tbody>
                                <tr>
                                    <th>Categories</th>
                                    <td>{categories?.length > 0 && categories.map((ele) => {
                                        return (<span className="tattoo-cat" key={ele?.id}>{ele?.name}</span>)
                                    })}</td>
                                </tr>

                                <tr>
                                    <th>Creator</th>
                                    <td>{creator?.name}</td>
                                </tr>
                                <tr>
                                    <th>Likes</th>
                                    <td>{tattoo?.likes}</td>
                                </tr>
                                <tr>
                                    <th>Attachment</th>
                                    <td><img height="500" src={`${tattoo?.imageUrl}`} /></td>
                                </tr>
                                {
                                    tattoo?.thumbUrl && <tr>
                                        <th>Thumbnail</th>
                                        <td><img src={`${tattoo?.thumbUrl}`} /></td>
                                    </tr>
                                }
                                <tr>
                                    <th>Created At</th>
                                    <td>{moment.unix(tattoo?.createdAt).format('YYYY-MM-DD HH:mm A')}</td>
                                </tr>

                            </tbody>
                        </Table>)
                    }
                </div>
            </div>
        </div>
    );
}
